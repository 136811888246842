import { useCustomSession } from "shared/context/CustomSessionContext";
import { useCustomRouter } from "shared/context/CustomRouterContext";
import { SessionView } from "shared/mappers/database/session/session";
import {
  AdminPermissions,
  AdminUserPermissions,
} from "shared/types/admin/permissions";

export const useAdminPermissions = (
  requiredPermissions: AdminPermissions[] = [],
) => {
  const adminSessionContext = useCustomSession();
  const userPermissions =
    adminSessionContext.societyAdmin?.societies.find(
      (society) => society.societyId == adminSessionContext.societyId,
    )?.permissions ?? [];
  const router = useCustomRouter();
  if (
    adminSessionContext.societyAdmin?.permissions.includes(
      AdminUserPermissions.super_admin,
    ) ||
    requiredPermissions.every((permission) =>
      userPermissions.includes(permission),
    )
  ) {
    return;
  }
  router.push("/forbidden");
};

export const hasAdminPermission = ({
  session,
  permissions,
}: {
  session?: Partial<SessionView>;
  permissions: AdminPermissions[];
}) => {
  const userPermissions = session?.societyAdmin?.permissions ?? [];
  if (userPermissions.includes(AdminUserPermissions.super_admin)) {
    return true;
  }
  const societyPermissions =
    session?.societyAdmin?.societies?.find(
      (perm) => perm.societyId === session.societyId,
    )?.permissions ?? [];

  return (
    permissions.every((permission) => userPermissions.includes(permission)) ||
    permissions.every((permission) =>
      societyPermissions.includes(permission),
    ) ||
    false
  );
};
